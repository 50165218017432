import styled from 'styled-components';

const Container = styled.div`
  max-width: ${props => props.theme.grid.maxWidth};
  margin: 0 auto;
  width: 100%;
  padding: 0 ${props => props.theme.spacing.base};

  ${props => props.theme.breakpoints.medium`
    padding: 0 ${props.theme.spacing.midLarge};
  `}

  ${props =>
    props.narrow &&
    `
    max-width: ${props.theme.grid.maxWidthNarrow};
  `}

  ${props =>
    props.xNarrow &&
    `
    max-width: ${props.theme.grid.maxWidthXnarrow};
  `}

${props =>
  props.hasSidebar &&
  props.theme.breakpoints.medium`

    margin-left: ${props.theme.components.sidebarWidth};
    width: calc(100% - ${props.theme.components.sidebarWidth});
    padding-left: ${props.theme.spacing.xLarge};
    max-width: calc(${props.theme.grid.maxWidth} - ${
    props.theme.components.sidebarWidth
  } + ${props.theme.spacing.xLarge});
  `}
`;

export default Container;
