import { RichText } from 'prismic-reactjs';

export const extractPrismicString = data => {
  if (!data) return null;

  return RichText.asText(data);
};

export const extractPrismicContent = data => {
  if (!data) return null;

  const mutatedData = data?.map(({ spans, ...item }) => ({
    spans: spans?.map(({ data: spanData, ...span }) => ({
      data: {
        ...spanData,
        url: spanData?.url?.replace(/(https:\/\/\/)/, '/'),
      },
      ...span,
    })),
    ...item,
  }));

  return RichText.render(mutatedData);
};

export const hasContent = data => {
  if (!data?.length) return false;

  return data.length > 1 || data[0]?.text !== '';
};
