import theme, { colors } from './main';

const buttonBase = `
  background-color: ${colors.buttonPrimary};
  border-radius: ${theme.base.radiusSmall};
  font-size: ${theme.fontSizes.base};
  color: ${colors.white}!important;
  font-weight: ${theme.fontWeights.bold};
  padding: ${theme.spacing.xSmall} ${theme.spacing.midLarge};
  position: relative;
  line-height: 1;
  text-decoration: none;
  min-width: 180px;
  text-align: center;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active {
    background-color: ${colors.buttonActive};
    text-decoration: none !important;
  }
`;

export default buttonBase;
