import styled from 'styled-components';

const Section = styled.section`
  padding: ${props => props.theme.spacing.midLarge} 0;
  position: relative;
  width: 100%;

  ${props => props.theme.breakpoints.medium`
    padding: ${props.theme.spacing.xLarge} 0;
  `}

  ${props =>
    props.smallPadding &&
    `
    padding: ${props.theme.spacing.base} 0;
  `}

  ${props =>
    props.smallPadding &&
    props.theme.breakpoints.medium`
    padding: ${props.theme.spacing.midLarge} 0;
  `}

  ${props =>
    props.padding === 'small' &&
    props.theme.breakpoints.medium`
    padding: ${props.theme.spacing.xLarge} 0;
  `}

  ${props =>
    props.padding === 'medium' &&
    props.theme.breakpoints.medium`
    padding: ${props.theme.spacing.xxLarge} 0;
  `}

  ${props =>
    props.padding === 'large' &&
    props.theme.breakpoints.medium`
    padding: ${props.theme.spacing.xxxLarge} 0;
  `}


  ${props =>
    props.backgroundColor === 'grey' &&
    `
      background-color: ${props.theme.colors.grey100};
    `}

${props =>
  props.noPaddingTop &&
  `
    padding-top: 0;
  `}

${props =>
  props.noPaddingTop &&
  props.theme.breakpoints.medium`
    padding-top: 0;
  `}

${props =>
  props.noPaddingBottom &&
  `
    padding-bottom: 0;
  `}

${props =>
  props.noPaddingBottom &&
  props.theme.breakpoints.medium`
    padding-bottom: 0;
  `}

  ${props =>
    props.white &&
    `
    background-color: ${props.theme.colors.white};
  `}
`;

export default Section;
