import { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ id, children }) => {
  const el = useRef(
    document.getElementById(id) || document.createElement('div'),
  );
  const [dynamic] = useState(!el.current.parentElement);
  useEffect(() => {
    if (dynamic) {
      el.current.id = id;
      document.body.appendChild(el.current);
    }
  }, [dynamic, id]);
  return createPortal(children, el.current);
};
export default memo(Portal);
